exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-precalificar-js": () => import("./../../../src/pages/precalificar.js" /* webpackChunkName: "component---src-pages-precalificar-js" */),
  "component---src-pages-pymes-js": () => import("./../../../src/pages/pymes.js" /* webpackChunkName: "component---src-pages-pymes-js" */),
  "component---src-pages-reporte-js": () => import("./../../../src/pages/reporte.js" /* webpackChunkName: "component---src-pages-reporte-js" */),
  "component---src-pages-resultado-js": () => import("./../../../src/pages/resultado.js" /* webpackChunkName: "component---src-pages-resultado-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */)
}

