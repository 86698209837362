import { createTheme } from "@mui/material/styles";

let themeMode = 'light'
// if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//     // dark mode
//     themeMode = 'dark'
// }

let theme = createTheme({
    palette: {
        mode: themeMode,
        primary: {
            main: "#fd9e45"
        },
        secondary: {
            main: "#25317e"
        }
    }
});

theme = createTheme(theme, {
    components: {
        MuiStep: {
            styleOverrides: {
                root: {
                    color: "#65B32E",
                    "&.Mui-completed": {
                        color: "#65B32E"
                    }
                }
            },
        },
    }
})

export default theme;